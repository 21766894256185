import * as React from "react";

import { Header } from "components/Header";

export default class extends React.PureComponent {
    public render() {
        return (
            <>
                <Header slugTitle="Not found" />
                <div>
                    <h1>Not found</h1>
                    <p>Woops! How embarrassing, this page does not exist.</p>
                </div>
            </>
        );
    }
}
